import React, { useState, useEffect } from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel'
import styles from './ManagementAreaStatusDropdown.module.css';
import { fetchWithAuthorisationHeader, postWithAuthorisationHeader } from '../../../services/AuthenticationService';
import settings from '../../../config';
import { Radio } from '@mui/material';

interface IProps {
    managementAreaId: number;
    onStatusUpdated: Function;
    isOpen: boolean;
}

interface IManagementAreaStatus {
    id: number;
    color: number;
    name: string;
    isSelected: boolean;
}

const labelStyle = {
    label: {
        fontSize: 14
    },
    labelSelected: {
        fontSize: 14,
        color: '#F7F4A8'
    }
};

export function ManagementAreaStatusDropdown(props: IProps) {

    const defaultStatuses: IManagementAreaStatus[] = [];
    const [maStatuses, setMaStatuses] = useState(defaultStatuses);
  
    //Handle when management area has been changed
    useEffect(() => {
        async function getStatuses() {
            try {
                let statuses: IManagementAreaStatus[] = [];
                statuses.forEach(s => s.isSelected = false);
                         
                var response = await fetchWithAuthorisationHeader(settings.CMPLY_API_URL + "managementArea/statuses/");
                var statusesResponse = response.data;

                statusesResponse.forEach(((status: { name: any; id: any; color: any }) => {
                    var newStatus = { id: status.id, name: status.name, color: status.color, isSelected: false };
                    statuses.push(newStatus);
                }));

                setMaStatuses(statuses);
            }
            catch (e) {
                console.log(e);
            }
        }
        // No need to fetch statuses if it has been fetched once, as the statuses are the same for a particular organisation.
        if (maStatuses.length <= 0) {
            getStatuses();
        }
    }, [props.managementAreaId]);

    async function changeManagementAreaStatus(status: any) {
        status.isSelected = !status.isSelected;
        setMaStatuses(maStatuses.slice());

        var data = {
            managementAreaId: props.managementAreaId,
            statusId: status.id
        }
        var response = await postWithAuthorisationHeader(settings.CMPLY_API_URL + "managementArea/update/", data);
        if (response.status === 200) {
            props.onStatusUpdated(status.id);
        }
        else {
            console.log("Failed to update management area status:" + response.statusText);
        }

        //reset the radio selection after update
        status.isSelected = !status.isSelected;
        setMaStatuses(maStatuses.slice());
    }

    let options = maStatuses.map(status => {
        return (
            <FormControlLabel
                key={status.name}
                sx={{ '& .MuiFormControlLabel-label': status.isSelected ? labelStyle.labelSelected : labelStyle.label }}
                control={<Radio color="secondary"
                style={{ opacity: '0.5 !important' }}
                onChange={() => changeManagementAreaStatus(status)} name={status.name} size={'small'} />}
                label={status.name}
                checked={status.isSelected}
            />
        );
    });

    return (
        <div className={styles.container} style={{ left: 130, bottom: 825,display: props.isOpen ? "block" : "none" }}>
            <div className={styles.content}>
                <FormGroup>
                    {options}
                </FormGroup>
            </div>
          </div>
    );
}
