import React, { useState, useEffect } from 'react';
import { Box } from '@mui/system';
import { Grid, Typography } from '@mui/material';
import { Avatar, Card, CardActions, CardContent, LinearProgress, Tooltip } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Chip, Divider, IconButton } from '@mui/material';
import { IAssetDetail } from '../../models/IAssetDetail';
import { IAssetDetailTask } from '../../models/IAssetDetailTask';
import { IProgramNameType } from '../../models/IProgramNameType';
import { IProgram } from "../../models/IProgram";
import { IProgramAsset } from "../../models/IProgramAsset";
import { fetchWithAuthorisationHeader, getTokenClaims } from '../../services/AuthenticationService';
import { Programs } from "../ManagementAreaSearch/ManagementAreaSearchResult/Programs/Programs";
import { SpanStatusColor } from '../../Enum/SpanStatusColor';
import { ISnackbarConfig } from "../../models/ISnackbarConfig";
import { WarningSnackbar } from '../WarningSnackbar/WarningSnackbar';
import settings from '../../config';
import moment from 'moment';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import NatureIcon from '@mui/icons-material/Nature';
import FlipCameraAndroidIcon from '@mui/icons-material/FlipCameraAndroid';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import WysiwygIcon from '@mui/icons-material/Wysiwyg';
import ErrorIcon from '@mui/icons-material/Error';
import { prettyJSON } from '../../services/PrettyJSON';

import styles from './AssetDetail.module.css';

interface IProps {
  assetId: number;
  cycleId: string;
  managementAreaId: number;
  onClickBack: Function;
  onAssetTaskSelected: Function;
}

export function AssetDetail(props: IProps) {
    const [assetDetail, setAssetDetail] = useState<IAssetDetail | null>(null);
    const lastAssetIdRef = React.useRef(0);
    const [orderedMetadata, setOrderedMetadata] = useState<any>({});
    const [showMetadataList, setShowMetadataList] = useState(true);
    const [isMetaData, setIsMetaData] = useState(false); // state to know if the asset has meta data or not.
    const [showTasksList, setShowTasksList] = useState(true);
    const [assetPrograms, setAssetPrograms] = useState<IProgram[]>([]);
    const [programAssetValues, setProgramAssetValues] = useState<any[]>([]);
    const [canAims3d, setCanAims3d] = useState(false);
    const [noSpatialDataSnackbarConfig, setNoSpatialDataSnackbarConfig] = useState<ISnackbarConfig | null>(null);

    function getProgramTypeIcon(programType:string):JSX.Element{
        if ( programType.toLowerCase() === 'cyclic' )
            return <Avatar className={`${styles.avatar} ${styles.cyclic}`}> <FlipCameraAndroidIcon /></Avatar>;
        else
            return <Avatar className={`${styles.avatar} ${styles.other}`}><NatureIcon /></Avatar>
    }

    useEffect(() => {
        async function getAssetDetail() {
          try {
            var response = await fetchWithAuthorisationHeader(settings.CMPLY_API_URL + 'asset/detail/' + props.assetId + '/' + props.managementAreaId + '/' + props.cycleId);
            let theAssetDetail = response.data as IAssetDetail;              
              if (theAssetDetail != null && (theAssetDetail.wkt == null || theAssetDetail.wkt == '' || theAssetDetail.wkt.includes('EMPTY') )) {
                  setNoSpatialDataSnackbarConfig({ messageType: 'warning', message: 'No spatial data', verticalAnchorOrigin: 'top', autoHideDuration: 5000 });
              }
              else {
                  setNoSpatialDataSnackbarConfig(null);
              }
            if (theAssetDetail.metadata) {
                setIsMetaData(true);
                setOrderedMetadata(
                    Object.keys(theAssetDetail.metadata)
                        .sort((a: string, b: string) => a.toLowerCase().localeCompare(b.toLowerCase()))
                        .reduce((obj: any, key: string) => {
                            obj[key] = theAssetDetail.metadata[key];
                            return obj;
                        }, {})
                );
            } else {
                setIsMetaData(false);
                setOrderedMetadata({});
            }

            let programs:IProgram[] = [];
            theAssetDetail.programs.forEach( (p:IProgramNameType ) => programs.push( { programId: p.programId, programName: p.name, programType: p.type } ));
            setAssetPrograms( programs );

            let theProgramAssetValues: any[] = [];

            theAssetDetail.programAssets.forEach( (programAsset:IProgramAsset) => {
                var program = programs.find(p => p.programId === programAsset.programId);

                theProgramAssetValues.push({key: 'Program', value: program != null ? program.programName : 'UNKNOWN' });
                theProgramAssetValues.push({key: 'Hazard Tree', value: programAsset.clearOfHazardFlag == true ? 'True' : 'False' });
                theProgramAssetValues.push({key: 'Completion Date', value: programAsset.completionDate != null ? moment(programAsset.completionDate).local().format('DD-MM-YYYY') : '' });
                theProgramAssetValues.push({key: '', value: '' });
            });

            setProgramAssetValues( theProgramAssetValues );

            setAssetDetail(theAssetDetail);            
            setShowMetadataList(true);
            setShowTasksList(true);
          } catch (e) {
            console.log(e);
            setAssetDetail(null);
          }
        }

        props.onAssetTaskSelected(0);

        if (props.assetId > 0 && props.assetId != lastAssetIdRef.current) {
            lastAssetIdRef.current = props.assetId;
            getAssetDetail();
        }
        else {
            if (assetDetail != null && (assetDetail.wkt == null || assetDetail.wkt == '' || assetDetail.wkt.includes('EMPTY'))) {
                setNoSpatialDataSnackbarConfig({ messageType: 'warning', message: 'No spatial data', verticalAnchorOrigin: 'top', autoHideDuration: 5000 });
            }
            else {
                setNoSpatialDataSnackbarConfig(null);
            }
        }
      }, [props.assetId]);

    useEffect(() => {
        var claims = getTokenClaims();
        var products = claims['products'];
        if (products.includes('AIMS 3D')) {
            setCanAims3d(true);
        }

    }, []);


    const openInNew = async (assetTaskId: number, taskTypeName : string) => {
      var response = await fetchWithAuthorisationHeader(settings.CMPLY_API_URL + 'Task/taskDetail/' + assetTaskId + '/' + props.assetId + '/' + props.managementAreaId + '/' + props.cycleId);
      
      let task = response.data as any;
      delete task['createdDate'];
      delete task['updatedDate'];
      delete task['createdBy'];
      delete task['updatedBy'];
      
      let json = JSON.stringify(task, undefined, 4);
      let formatted = prettyJSON(json);
      let h3 = '<h3 style="font-family: monospace;">{0}: {1}</h3>';
      let newwin = window.open();
      newwin?.document.write(
          '<html><title>' +
              task.assetExternalReferenceId +
              '-' +
              taskTypeName +    
              '</title><body style="background-color:#121212;color: #FFF; font-size: 14px;">' +
              h3.replace('{0}', 'Task type').replace('{1}', taskTypeName) +
              h3.replace('{0}', 'Company').replace('{1}', task.company) +
              '<pre width="80" style="outline: 1px solid #ccc; padding: 5px; margin: 5px;">' +
              formatted +
              '</pre></body></html>'
      );
    };

    const openInAIMS = (centroid: string) => {
        if (centroid.includes('POINT')) {
            centroid = centroid.substring(0, centroid.length - 2);
            centroid = centroid.slice(6);
            let coordinates = centroid.split(' ');
            if (coordinates.length === 2) {
                window.open(settings.AIMS3D_URL + '#/home?lat=' + coordinates[1] + '&lon=' + coordinates[0] + '', '_blank', 'noreferrer');
            } else {
                return;
            }
        } else {
            return;
        }
    };

  return (
    <Card style={{height:'100%', overflowY:'scroll'}} >
        <CardActions style={{ paddingLeft: 0 }}>
            <Grid container justifyContent="flex-end">
                <Grid item xs={6} sx={{ textAlign: 'left' }}>
                    <IconButton
                        aria-label="back"
                        style={{ color: '#E7E69E' }}
                        onClick={() => {
                            props.onClickBack();
                            setNoSpatialDataSnackbarConfig(null);
                        }}
                    >
                        <ArrowBackIcon style={{ height: 18 }} />
                        <Typography style={{ fontSize: 15}}>{assetDetail != null ? assetDetail.managementAreaName : 'BACK'}</Typography>
                    </IconButton>
                </Grid>
                <Grid item xs={6} sx={{ textAlign: 'right' }}>
                    {canAims3d && assetDetail !== null && assetDetail.centroid !== null && (
                        <Box>
                            <Tooltip title={'Open this span location in AIMS3D'}>
                                <IconButton
                                    onClick={() => {openInAIMS(assetDetail.centroid);}}>
                                    <Typography color="primary" style={{ fontSize: 15 }} variant="subtitle1">
                                        View in AIMS3D
                                    </Typography>
                                    <OpenInNewIcon sx={{ marginLeft: '4px', fontSize: 18 }} color="primary" />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    )}
                </Grid>
            </Grid>
        </CardActions>
        <CardContent>
            <div style={{ height: '100%' }}>
                  <Box display="flex" style={{ textAlign: 'left', fontWeight: 400, height: '100%' }}>
                    {assetDetail == null && (
                      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" style={{ height: '100%' }}>
                        <LinearProgress style={{ width: '260px', color: 'red', marginBottom: '8px' }} />
                      </Box>
                    )}
                      {(assetDetail != null &&
                           
                          <Grid container spacing={2}>
                              <Grid item >
                                   <WarningSnackbar config={noSpatialDataSnackbarConfig} /> 
                              </Grid>
                        <Grid item xs={1}>
                          <Box className={styles.itemHeader}>
                            <Typography variant="subtitle1">Span</Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={2}>
                          {assetDetail.createdByAPI ? (<Box sx={{ marginLeft: '10px' }}>
                             <Chip size="small" label={'New'} style={{ color: 'black', backgroundColor: '#CE93D8'}} />
                          </Box>): <></>}
                              </Grid>
                              <Grid item xs={8}>        
                                  {(assetDetail.wkt == null || assetDetail.wkt == "" || assetDetail.wkt.includes("EMPTY")) &&
                                     (<Box sx={{ marginLeft: '10px' }}>
                                      <Grid container>
                                          <Grid item xs={1}>
                                          <ErrorIcon sx={{ marginLeft: '4px', fontSize: 18 }} style={{ fill: '#EE924F' }} /></Grid>
                                          <Grid item xs={11}> <Typography sx={{ marginLeft: '4px' }} variant="subtitle2">No spatial data</Typography></Grid>
                                      </Grid>
                                      </Box>)}
                                  </Grid>
                        <Grid item xs={8}>
                          <Box className={styles.itemContent}>
                            <Typography variant="h5">{assetDetail.externalReferenceId}</Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={4}>
                          <Box sx={{ marginTop: '2px' }}>
                             <Chip size="small" label={assetDetail.statusAcrossAllPrograms} style={{ color: 'black', backgroundColor: SpanStatusColor[assetDetail.statusAcrossAllPrograms as keyof typeof SpanStatusColor]}} />
                          </Box>
                        </Grid>
                        <Grid item xs={12}>
                          <Divider light={true} />
                        </Grid>
                        <Grid item xs={11}>
                          <Box className={styles.itemHeader}>
                            <Typography variant="subtitle2">Attributes</Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={1} alignSelf="center" justifySelf="flex-end">
                          <Box>
                            <IconButton onClick={(e: any) => setShowMetadataList(!showMetadataList)} style={{ marginLeft: 8, height: 17, width: 17 }}>
                              {showMetadataList ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                            </IconButton>
                          </Box>
                        </Grid>
                        {showMetadataList && (
                          <Grid item xs={12}>
                            { isMetaData ? Object.keys(orderedMetadata).map( (key:any) => {
                              return <Grid container >
                                          <Grid item xs={6}>
                                              <Typography variant="h6" style={{fontSize:12, color:'#747474'}}>                    
                                                  {key}
                                              </Typography>
                                          </Grid>
                                          <Grid item xs={6}>
                                              <Typography variant="h6" style={{fontSize:12, color:'#ffffff'}}>                    
                                                  {orderedMetadata[key]}
                                              </Typography>
                                          </Grid>
                                      </Grid>
                              }) : (<Grid item textAlign={'center'} xs={12}>  <Typography variant="subtitle2">No metadata available</Typography></Grid>)}
                          </Grid>
                        )}
                        <Grid item xs={12}>
                          <Divider light={true} />
                        </Grid>
                        <Grid item xs={3}>
                            <Box className={styles.itemHeader}>
                                <Typography variant="subtitle2">
                                    Programs
                                </Typography>
                            </Box>
                            <Box style={{marginTop:5, position:'relative'}}>
                                <Programs programs={assetPrograms}/> 
                            </Box>
                        </Grid>
                          <Grid item xs={12}>
                            { programAssetValues.map( (obj:any) => {

                                return <Grid container >
                                            <Grid item xs={6}>
                                                <Typography variant="h6" style={{fontSize:12, color:'#747474',height:20}}>                    
                                                    {obj.key}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                 <Typography variant="h6" style={{fontSize:12, color:'#ffffff'}}>                    
                                                    {obj.value}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                             }) }                
                          </Grid>
                        <Grid item xs={11} style={{paddingTop:0}}>
                          <Box className={styles.itemHeader}>
                            <Typography variant="subtitle2">Tasks</Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={1} alignSelf="center" justifySelf="flex-end" style={{paddingTop:0}}>
                          <Box>
                            <IconButton onClick={(e: any) => setShowTasksList(!showTasksList)} style={{ marginLeft: 8, height: 17, width: 17 }}>
                              {showTasksList ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                            </IconButton>
                          </Box>
                        </Grid>
                        {showTasksList && (
                          <Grid item xs={12}>
                            { assetDetail.tasks.map( (task:IAssetDetailTask) => {

                                return <Grid container style={{backgroundColor:'#111122',height: 50, textAlign:'left', marginBottom: 5}}>
                                            <Grid item style={{marginLeft: 10}}>
                                            </Grid>
                                            <Grid item xs={3} style={{margin: 'auto'}}>
                                                <Typography variant="h6" style={{fontSize:12, color:'#ffffff'}}>                    
                                                    {task.taskTypeName}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={3} style={{margin: 'auto'}}>
                                                <Typography variant="h6" style={{fontSize:12, color:'#ffffff'}}>                    
                                                    {task.taskFinalisedDate != null ? moment(task.taskFinalisedDate).local().format('DD-MM-YYYY') : ''}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={2} style={{margin: 'auto'}}>
                                                <Tooltip title={task.programName} >
                                                    { getProgramTypeIcon( task.programTypeName ) }
                                                 </Tooltip>
                                            </Grid>
                                            <Grid item xs={1} style={{margin: 'auto'}}>
                                                <Tooltip title={'Show details in popup'} >
                                                    <IconButton onClick={()=> props.onAssetTaskSelected( task.assetTaskId ) }><WysiwygIcon color='primary' /></IconButton>
                                                </Tooltip>
                                           </Grid>
                                            <Grid item xs={1} style={{margin: 'auto'}}>
                                                <Tooltip title={'Show raw details in new window'} >
                                                    <IconButton onClick={()=>{openInNew( task.assetTaskId, task.taskTypeName);}}><OpenInNewIcon color='primary' /></IconButton>
                                                </Tooltip>
                                           </Grid>
                                        </Grid>
                             })}                
                          </Grid>
                        )}

                      </Grid>
                    )}
                  </Box>
             </div>
        </CardContent>
    </Card>
  );
}