export enum GeometryType {
  Point,
  Line,
  Polygon,
  Composite
}

export interface IMapLayer {
    label: string,                          /* what name should appear on the layer control for this layer? */
    group: string,                          /* this is the name of the MapServer file 'GROUP' variable of which the layer belongs. This MUST match MapServer */
    thumbnail: string,                      /* thumbnail for the layer tab */
    hideFromMenu?: boolean | false,         /* OPTION: whether or not the user is able to see the layer control toggle for this layer */
    min: number,                            /* min zoom at which the layer will show  */
    max: number,                            /* max zoom at which the layer will show */
    tiled: boolean,                         /* should mapserver render this layer tile by tile, or as a map-dimension image? for performance reasons you might need to tweak this */
    zOrder: number,                         /* basically the draw order on the open layers map. */
    isActive:boolean,                       /* this flag is completely user controlled by checking the box */
    isAvailable:boolean,                    /* this flag is controlled by the parent, and overrides user check, in an event such as 'map zoom no longer in range'*/
    stateIsStatic:boolean,                  /* can the layer be modified by the user? if not then it is static and isActive will not be updated */
    onVisibilityToggle:Function,            /* callback fired when layer is toggled by the user */
    networkShapefileId?:number | null       /* OPTION: mandatory field for any user created map layers i.e. shapefile layers */
    geometryType:GeometryType               /* enum for defining render logic */
  }